import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import Layout from '../components/Layout';

import { store, persistor } from '../store';

import Route from '../Route';

import { ContainerPdf } from '../styles/styles';

export default function Pdf() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <ContainerPdf>
            <Route />
          </ContainerPdf>
        </Layout>
      </PersistGate>
    </Provider>
  );
}
